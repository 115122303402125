// styles
import '@scss/app.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-lightbox/dist/slick-lightbox.css'
import 'plyr/dist/plyr.css'

import $ from 'jquery'

window.$ = $
window.jQuery = $

import('@js/custom/main.js')

// required for HMR to work
if (import.meta.hot) {
    import.meta.hot.accept()
}
